import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, useStaticQuery } from 'gatsby';
import moment from 'moment';
import SEO from '../components/helpers/SEO';
import Base from '../templates/Base';
import { Box, Button, Col, Container, Divider, Title } from '../components/atoms';
import Author from '../components/molecules/Author';
import { styledSpaces } from '../utils';
import { format } from '../components/helpers/contentful';

const BlogPost = ({ node, ...rest }) => {
  const { slug, title, shortDescription, publishDate, author } = node;

  return (
    <Col width={[1, null, 1 / 2]} mb={[64, null, null, 96]} {...styledSpaces(rest)}>
      <Title mb="m" size="h4" as="h2">
        <Link to={`/${slug}`}>{title}</Link>
      </Title>
      <Author mb="l" date={publishDate} author={author} />
      <Box mb={['l', 'xl', 'xxl']}>{format(shortDescription.json)}</Box>
      <Button to={`/${slug}`}>read article</Button>
    </Col>
  );
};

BlogPost.propTypes = {
  node: PropTypes.shape({
    slug: PropTypes.string,
    title: PropTypes.string,
    publishDate: PropTypes.string,
    shortDescription: PropTypes.shape({
      json: PropTypes.shape(),
    }),
    author: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
};

const HomePage = () => {
  const {
    contentfulHomepage,
    allContentfulBlogPost: { edges: posts },
  } = useStaticQuery(graphql`
    query {
      contentfulHomepage {
        metaTitle
        metaDescription
        h1
        subHeading
      }
      allContentfulBlogPost {
        edges {
          node {
            id
            publishDate
            slug
            title
            author {
              name
              avatar {
                id
                file {
                  url
                }
              }
            }
            shortDescription {
              json
            }
          }
        }
      }
    }
  `);

  const { metaTitle, metaDescription, h1, subHeading } = contentfulHomepage;

  const postsSorted = posts
    .slice()
    .sort(
      (a, b) =>
        moment(a.node.publishDate).format('YYYYMMDD') -
        moment(b.node.publishDate).format('YYYYMMDD'),
    );

  const postsReversed = postsSorted.slice().reverse();

  return (
    <Base>
      <SEO title={metaTitle} description={metaDescription} />
      <Container mt={['l', 56, 76]} mb={['xxl', 96, null, 120]}>
        <Title as="h1" size="h1" mb={['xl', 80]}>
          {h1}
        </Title>
        <Title size="h4" mb={0}>
          {subHeading}
        </Title>
      </Container>
      <Container mb="xxl" display={['block', 'none']}>
        <Divider />
      </Container>
      <Container withRow row={{ gap: ['xl', 'xxl', 72, 96, 128] }} mb={[0, 's', 56]}>
        {postsReversed.map(({ node }) => {
          return <BlogPost key={node.id} node={node} />;
        })}
      </Container>
    </Base>
  );
};

export default HomePage;
